
import React from 'react'



export const Footer = () =>
    <>
    <nav role="navigation" className="footer-nav"> <p><span role="img" aria-label="Sparkles">✨</span>thanks for visiting <span role="img" aria-label="Sparkles">✨</span></p>
    </nav>
    <footer role="contentinfo">
        <span className="credit">Built by <a href="https://madeleinehodges.com/"> Maddy Hodges</a>. </span>
    </footer>
    </>


 



/*

 <span>If you like this, you may like <a href="mailto:mfhodges95@gmail.com?subject=Hey%20Madeleine👋&body=I%20loved%20your%20beanie%20Babies%20site.%20Lets%20connect%20and%20chat%20about%20getting%20you%20a%20job.">THIS</a>. <br></span>
*/








